/* FAQ Section */
.faq-section {
  width: 100%;
  margin: 0px auto var(--section-space) auto;
  padding: 0px 20px;
}

.faq-section h2 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 40px;
  color: #003366;
}

.faq-item {
  background-color: #f9f9f9;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease;
}

.faq-item:hover {
  background-color: #f0f0f0;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: left;
  text-align: left;
  font-size: 1.1rem;
  color: #003366;
}

.faq-answer {
  margin-top: 10px;
  font-size: 1.1rem;
  line-height: 1.6;
  color: #666;
  text-align: left;
}

.faq-answer p {
  margin: 0;
}