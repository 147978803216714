.info-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Center the title horizontally */
  padding: 0px 20px;
  max-width: 100%;
  /* Ensure it takes up the full width */
  margin-bottom: var(--section-space);
}

.info-title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 40px;
  color: #003366;
  text-align: center;
}

.info-content {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  gap: 40px;
  min-height: 400px;
}

.info-text {
  flex: 1;
  text-align: left;
  padding-right: 20px;
  font-size: 1.1rem;
  line-height: 1.6;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.info-text p {
  margin-bottom: 20px;
}

.info-image {
  flex: 1;
  display: flex;
  align-items: stretch;
  position: relative;
}

.info-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease;
}

.info-image img:hover {
  transform: scale(1.02);
}

/* Responsive behavior */
@media (max-width: 800px) {
  .info-section {
    padding: 0 15px;
  }

  .info-title {
    font-size: 1.75rem;
    margin-bottom: 30px;
  }

  .info-content {
    flex-direction: column;
    gap: 25px;
    min-height: auto;
  }

  .info-text {
    padding-right: 0;
    margin-bottom: 0;
    font-size: 1rem;
  }

  .info-image {
    height: 250px;
    width: 100%;
  }
}

/* Small mobile devices */
@media (max-width: 480px) {
  .info-title {
    font-size: 1.5rem;
    margin-bottom: 25px;
  }

  .info-content {
    gap: 20px;
  }

  .info-image {
    height: 200px;
  }
}