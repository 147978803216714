.team-section {
  padding: 0px 20px;
  margin-bottom: var(--section-space);
}

.team-container {
  max-width: 100%;
  margin: 0 auto;
}

.team-section h2 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 40px;
  color: #003366;
  text-align: center;
}

.team-intro {
  font-size: 1.3rem;
  line-height: 1.6;
  color: #003366;
  margin-bottom: 60px;
  text-align: left;
  font-weight: 600;
}

.team-member {
  display: flex;
  gap: 40px;
  margin-bottom: 60px;
  align-items: flex-start;
}

.team-member-photo {
  flex: 1;
  max-width: 33.333%;
}

.team-member-photo img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.team-member-bio {
  flex: 2;
  font-size: 1.1rem;
  line-height: 1.6;
  color: #333;
  text-align: left;
}

.team-member-bio strong {
  display: block;
  font-size: 1.3rem;
  color: #003366;
  margin-bottom: 15px;
  text-align: left;
}

.team-ecosystem {
  text-align: left;
  margin-top: 60px;
  padding: 40px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.team-ecosystem p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #333;
  margin: 0;
}

.team-ecosystem a {
  color: #003366;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease;
}

.team-ecosystem a:hover {
  color: #0056b3;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .team-member {
    flex-direction: column;
    gap: 20px;
  }

  .team-member-photo {
    max-width: 100%;
  }

  .team-member-photo img {
    max-width: 300px;
    margin: 0 auto;
    display: block;
  }

  .team-ecosystem {
    padding: 20px;
  }

  .team-member-bio strong {
    text-align: left;
  }
}