:root {
  --primary-color: #0b4b93;
  --primary-color-hover: #2f5aae;
  --main-description-color: rgba(88, 88, 88, 0.856);
  --section-space: 80px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  text-align: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  padding: 0 20px;

}

.App-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.logo img {
  height: 35px;
}

.header-buttons {
  display: flex;
  gap: 10px;
}

.lang-button {
  background-color: transparent;
  color: black;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 0.6rem;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.lang-button:hover {
  background-color: #c9c9c9;
}

.App-main {
  margin-top: 100px;
}

.main-title {
  font-size: 3.6rem;
  font-weight: 700;
  margin-bottom: var(--section-space);
  width: 100%;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  letter-spacing: -2.0px;
}

.main-description {
  font-size: 1.46rem;
  font-weight: 600;
  margin-bottom: var(--section-space);
  letter-spacing: -0.025rem;
  width: 100%;
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: var(--main-description-color);
  line-height: 1.5;
}

.image-section {
  width: 100%;
  margin: var(--section-space) auto;
  padding: 0px 20px;
}

.image-container {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.screenshot-image {
  width: 100%;
  height: auto;
  display: block;
}


/* Default state */
a {
  text-decoration: none;
  /* Remove underline */
  color: var(--primary-color);
  /* Default link color */
  font-weight: 600;
}

/* Hover state */
a:hover {
  color: var(--primary-color-hover);
  /* Color on hover */
}

/* Visited state */
a:visited {
  color: var(--primary-color);
  /* Color for visited links */
}